import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

export const Component_noDress = ({imt}) => {

  const navigate = useNavigate();  

  return (
    <tr key={imt._id}>
      <td><small>{imt.rut}</small></td>
      <td><small>{imt.turno}</small></td>
      <td><small>{imt.fecha}</small></td>
      <td className='text-center'>
        <button
          disabled={!imt.state}
          className='btn btn-sm btn-danger'
          onClick={()=>navigate('/direccion_link',{state:{rut:imt.rut}})}>
          <AddLocationAltOutlinedIcon style={{fontSize: '18px'}} />
        </button>
      </td>
    </tr>
  )
}
