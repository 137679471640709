import React, { useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'

import dayjs from 'dayjs'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { GetUserTransport } from '../../api/GetUserTransport.api'

import BorderColorIcon from '@mui/icons-material/BorderColor';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { EditarTrasnporte } from '../../component/EditarTrasnporte'
import { useNavigate } from 'react-router-dom'
import { DeleteTransport } from '../../api/DeleteTransport.api'

var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export const FindUserEdit = () => {

  const navigate = useNavigate();

  const [Rut, setRut] = useState('');
  const [TheWeek, setTheWeek] = useState({"semana":"", "fecha":""})
  const [semana, setN_Week] = useState([]);
  const [UserList, setUserList] = useState([])

  const ParseRut = async (e) => {
    if(e.target.value.length <= 10){
      var clean = e.target.value.replace(/-/g,"")
      var largo = clean.length
      var prin = clean.substr(0,(largo-1));
      var nal = clean.substr((largo-1),largo)
      let rutPaser = prin+"-"+nal
      await setRut(rutPaser)
    }
  }

  const HandleFindUser = async () => {
    let data = {}
    data['rut'] = Rut
    data['semana'] = TheWeek.semana
    data['fecha'] = TheWeek.fecha
    // console.log(Rut,TheWeek, data)
    let query = await GetUserTransport({data})
    await setUserList(query)
  }

  const DeleteTransportAsignt = async (id) => {
    let isEraser = window.confirm('Esta seguro de eliminar')
    if(isEraser){
      let wasDelte = await DeleteTransport({id});
      alert(wasDelte.msg)
      await HandleFindUser()
    }
  }

  useEffect(() => {
    // -2
    setN_Week([
      {
        "week": dayjs(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).toISOString().split('T',1)[0]
      },{
        "week": dayjs(dayjs().day(1).$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).$d).toISOString().split('T',1)[0]
      },{
        "week": dayjs(dayjs(dayjs().day(1).$d).add(1, 'week').$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).add(1, 'week').$d).toISOString().split('T',1)[0]
      },{
        "week": dayjs(dayjs(dayjs().day(1).$d).add(2, 'week').$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).add(2, 'week').$d).toISOString().split('T',1)[0] 
      }
    ])
  }, [])

  return (
    <>
      <HeaderMenu Pag_Sel={'buscar'} />
      
      <div className='container my-3'>
        {/* {console.log(TheWeek)} */}
        <div className='row'>
          <div className='col-12 performance-2 pb-3 mb-3'>
            <div className='row'>
              <div className="col-12 col-md-4">
              <label htmlFor="exampleInputEmail1">* Semana-Fecha</label>
                <FormControl fullWidth size='small'>
                  <Select
                    size='small'
                    id="demo-simple-select"
                    value={TheWeek.semana}
                    onChange={(e)=>{
                      let sm1 = semana.find(({week}) => week === e.target.value)
                      setTheWeek({["semana"]:e.target.value, ["fecha"]:sm1.fecha})
                    }
                    }
                  >
                    {
                      semana.map(itm=><MenuItem key={itm.week} value={itm.week}>{itm.week<10?"0"+itm.week:itm.week} - {itm.fecha}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-md-4">
                <label htmlFor="exampleInputEmail1">* Rut</label>
                <input type="text" name='rut' value={Rut} onChange={(e)=>ParseRut(e)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingrese Rut" autoComplete='off' />
              </div>
              <div className="col-12 col-md-4 pt-2 text-center">
                <br/>
                <Button variant='contained' onClick={()=>HandleFindUser()}>Buscar!</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container mt-2'>
        <div className='row'>
          {/* <div className='col-12'>
            
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col">RUT</th>
                  <th scope="col">NOMBRE</th>
                  <th scope="col">SEMANA</th>
                  <th scope="col">TELEFONO</th>
                  <th scope="col">ACCION</th>
                </tr>
              </thead>
              <tbody>
              {
                UserList.map(itm => (
                  <tr key={itm._id}>
                    <td>{itm.rut}</td>
                    <td>{itm.nombre}</td>
                    <td>{itm.semana}</td>
                    <td>{itm.telefono}</td>
                    <td>✏</td>
                  </tr>
                ))
              }
                
              </tbody>
            </table>
          </div> */}
          <div className='col-12 col-md-3'></div>
            {
              UserList.map(itm => (
                <div className='col-12 col-md-6 card-tr' key={itm._id}>
                  <div className='row'>
                    <div className='col-12 pt-2'><span className='card-title'>Rut</span><br/><span><SubdirectoryArrowRightIcon fontSize='small' /></span><span className='card-dato'>{itm.rut}</span></div>
                    <div className='col-12 pt-2'><span className='card-title'>Nombre</span><br/><span><SubdirectoryArrowRightIcon fontSize='small' /></span><span className='card-dato'>{itm.nombre}</span></div>
                    <div className='col-6 pt-2 pb-2'><span className='card-title'>Semana</span><br/><span><SubdirectoryArrowRightIcon fontSize='small' /></span><span className='card-dato'>{itm.semana}</span></div>
                    <div className='col-6 pt-2 pb-2'><span className='card-title'>Telefono</span><br/><span><SubdirectoryArrowRightIcon fontSize='small' /></span><span className='card-dato'>{itm.telefono}</span></div>
                    <div className='btn-edit' onClick={()=>navigate('/editar',{state:itm})}><BorderColorIcon /></div>
                    <div className='btn-trash' onClick={()=>DeleteTransportAsignt(itm._id,itm.nombre)}><DeleteForeverIcon /></div>
                  </div>
                </div>
              ))
            }
          <div className='col-12 col-md-3'></div>
        </div>
      </div>
    </>
  )
}
