import React from 'react'
import { URL_CTRL_TRASP, URL_SRV_RAIZ } from '../../router/Url';

export const MakeMeReport = ({inicio, fin, empresa}) => {
    // console.log(inicio, fin, empresa);

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_TRASP+'makemereportnow',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                inicio, fin, empresa
            })
        })
        let responsito = await query.json();
    
        if(responsito.success){
            resolve(responsito.success)
            let url = responsito.body;
            let a = document.createElement("a");
            a.href = URL_SRV_RAIZ+url;//to web add url_server+build/
            // console.log(a.href)
            // a.href = url;
            a.download = "transporte.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
            alert('En instantes se descargara su Archivo')
        }else{
            resolve(responsito.body)
        }
    })
}
