import React from 'react'
import { URL_CTRL_TRASP } from '../../router/Url';

export const CrudIntegrated = ({campo, accion, data, id}) => {
    // console.log(campo, accion, data, id);
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_TRASP+'crudintegrated',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
          body: JSON.stringify({
            campo, accion, data, id
          })
        })
        let responsito = await query.json();
        // console.log(responsito);
        if(responsito.success){
            resolve(responsito.success)
            alert(responsito.message)
        }else{
            resolve([])
        }
    })
}
