import React from 'react'
import { URL_CTRL_TRASP } from '../router/Url';

export const UpdatePhone = ({data, Telefono, USER}) => {
  // console.log({data, Telefono, USER})
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_TRASP+'updatethephone',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data, Telefono, USER
      })
    })
    let responsito = await query.json();

    if(responsito.data.success){
      resolve(responsito.data)
    }else{
      resolve(responsito.data)
    }
  })
}
