import { URL_CTRL_TRASP, URL_SRV_ARIZTIA } from '../router/Url';

export const GetUserByRut = ({rutPaser}) => {
  // console.log('first')
  return new Promise(async function(resolve, reject) {
    
        const query = await fetch(URL_CTRL_TRASP+`getuserbyrut?rut=${rutPaser}`)
        // const query = await fetch(URL_CTRL_TRASP+`is_address_ok?rut=${rutPaser}`)

        const reqkkst = await query.json();
        // console.log(reqkkst.type)
        // console.log(reqkkst.body)
        if(reqkkst.success){
          resolve({typo_:reqkkst.type,data_:reqkkst.body})
        }else{
          resolve(null)
        }
    
  })
}