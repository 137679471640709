import React from 'react'

export const GetHere = (at) => {
  let apiKey = '6s2mtkIWDVzqzmIBHC_gvtSTClaGZUFMtavScp2wRRw'//marcuxo'fDWFEfuyqdPfTBz5uw2W4AeUT_YBYG9ZDvdpNSvBYkw'
  let lang = 'es-CL'
  // console.log(at.lat,at.lng)
  return new Promise(async (resolve, reject) => {
    // let query1 = await fetch(`https://revgeocode.search.hereapi.com/v1/revgeocode?at=${at.lat.toFixed(5)},${at.lng.toFixed(5)}&lang=${lang}&apiKey=${apiKey}`,{
    //   method: 'GET',
    // })
    let query2 = await fetch(`https://api.tomtom.com/search/2/reverseGeocode/${at.lat},${at.lng}.json?key=7WWzH46TeXlK5G0iSzkfT2Ki1u5hbkAd`,{
      method: 'GET',
    })
    // let query3 = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${at.lat},${at.lng}&key=AIzaSyBThHdhGu5uQY5j-E4BmyJnHnkA7aP8v_E`,{
    //   method: 'GET',
    // })
    // let responsito1 = await query1.json();
    // console.log("HERE",responsito1.items[0].title)
    let responsito2 = await query2.json();
    // console.log("TOMTOM",responsito2.addresses[0].address.freeformAddress)
    // let responsito3 = await query3.json();
    // console.log("GUGLE",responsito3.results[0].formatted_address)
    // if(responsito.data.success){
      resolve(responsito2.addresses[0].address.freeformAddress)
      // resolve(responsito.addresses[0].address.freeformAddress)
    // }else{
    //   resolve(responsito.data)
    // }
  })
}
