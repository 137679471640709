import React from 'react'
import { URL_CTRL_TRASP } from '../../router/Url';

export const TrayectosList = () => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_TRASP+'gettrayecto',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        //   body: JSON.stringify({
        //     data, Conductor, Trayecto, Patente, timestamp
        //   })
        })
        let responsito = await query.json();
        // console.log(responsito);
        if(responsito.success){
            resolve(responsito.body)
        }else{
            resolve([])
        }
    })
}
