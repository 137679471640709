import React, { useContext, useEffect, useState } from 'react'
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import { UpdatePhone } from '../api/UpdatePhone.api';
import LoginContext from '../context/login_context/LoginContext';

export const Phone = ({Datos}) => {

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [Telefono, setTelefono] = useState('');
  const [IsOkPhone, setIsOkPhone] = useState(true);
  const [IsLoadinng, setIsLoadinng] = useState(false);
  const [IsFinEnd, setIsFinEnd] = useState(true);

  const ParseFone = async () => {
    let fone = Telefono
      if(fone.length === 9){
        setIsOkPhone(false)
        // setTelefono(fone.substring(0,1)+" "+fone.substring(1,5)+" "+fone.substring(5,9))
      }else{
        if(fone.length < 10){
          setIsOkPhone(true)
        }else{
          setIsOkPhone(false)
        }
       // console.log(Telefono.length)
      }
  }

  useEffect(() => {
    ParseFone()
  }, [Telefono])

  const SentToUpdatePhone = async (data) => {
    setIsLoadinng(true)
    let chngPhone = await UpdatePhone({data, Telefono,'USER':LoginState.user});
    if(chngPhone.success){
      setIsFinEnd(false)
    }else{
      setIsLoadinng(false)
    }
  }

  return (
    IsFinEnd?
    <tr key={Datos._id}>
      <td><small>{Datos.rut}</small></td>
      <td><small>{Datos.nombre}</small></td>
      <td>
      <input type="tel" name='telefono' value={Telefono} className="form-control form-control-sm" onChange={(e)=>setTelefono(e.target.value)} placeholder="9 1234 5678" autoComplete='off'/>
      </td>
      <td className='text-center'>
        {
          !IsLoadinng?
            <button disabled={IsOkPhone} className='btn btn-sm btn-danger' onClick={()=>SentToUpdatePhone(Datos)}>
              <AddIcCallOutlinedIcon style={{fontSize: '18px'}} />
            </button>
          :
            <button disabled={IsOkPhone} className='btn btn-sm btn-warning'>
              <AddIcCallOutlinedIcon style={{fontSize: '18px'}} />
            </button>
        }
        
      </td>
    </tr>
    :null
  )
}
