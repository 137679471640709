import React, { Fragment, useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { TextField } from '@mui/material';
import { ConductoresList } from '../../api/POST/ConductoresList.api';
import { PatentesList } from '../../api/POST/PatentesList.api';
import { TrayectosList } from '../../api/POST/TrayectosList.api';
import { CrudIntegrated } from '../../api/POST/CrudIntegrated.api';
import { MakeMeReport } from '../../api/POST/MakeMeReport.api';
import LoginContext from '../../context/login_context/LoginContext';

/** ICONS */
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PostAddIcon from '@mui/icons-material/PostAdd';


export const MantenedorAppDataGo = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta);

    const [TablaWidth, setTablaWidth] = useState(0);
    const [CampoAddConductor, setCampoAddConductor] = useState(false);
    const [AddConductor, setAddConductor] = useState('');
    const [CampoAddPatente, setCampoAddPatente] = useState(false);
    const [AddPatente, setAddPatente] = useState('');
    const [CampoAddTrayecto, setCampoAddTrayecto] = useState(false);
    const [AddTrayecto, setAddTrayecto] = useState('');

    const [List_Conductor, setList_Conductor] = useState([]);
    const [List_Patente, setList_Patente] = useState([]);
    const [List_Trayecto, setList_Trayecto] = useState([]);

    const [startDate, setStartDate] = useState(0);
    const [endtDate, setendtDate] = useState(0);

    const [ValiDate, setValiDate] = useState(true);

    const [ModalShowHid, setModalShowHid] = useState(false);

    const GetAnchoTabla = async () => {
       // tb_02
        let clientWidth_ = window.document.getElementById('tb_02').clientWidth;
        setTablaWidth(clientWidth_);
    }

    const ShowHidenCampoAdd = async (campo) => {
        switch (campo) {
            case "patente":
                setCampoAddPatente(!CampoAddPatente)
                break;

            case "trayecto":
                setCampoAddTrayecto(!CampoAddTrayecto)
                break;

            case "conductor":
                setCampoAddConductor(!CampoAddConductor)
                break;
        
            default:
                break;
        }

    }

    const HandleReloadData = async (cmpo, accin) => {
        if(cmpo === 'conductor'){
            const conductores = await ConductoresList();
            setList_Conductor(conductores);
            setAddConductor('')
            accin==='add'?setCampoAddConductor(!CampoAddConductor):null
        }
        if(cmpo === 'patente'){
            const patentes = await PatentesList();
            setList_Patente(patentes);
            setAddPatente('')
            accin==='add'?setCampoAddPatente(!CampoAddPatente):null
        }
        if(cmpo === 'trayecto'){
            const trayectos = await TrayectosList();
            setList_Trayecto(trayectos);
            setAddTrayecto('')
            accin==='add'?setCampoAddTrayecto(!CampoAddTrayecto):null
        }
    }

    /** Elimina un item de la lista seleccionada */
    const HandleAddDelete = async (campo, accion,id) =>{
        // console.log(campo, accion,id);
        if(accion === "add"){
            // console.log('in add');
            switch (campo) {
                case 'conductor':
                    let data_1 = await CrudIntegrated({campo:campo, accion:accion, data:AddConductor, id:id})
                    if(data_1) HandleReloadData(campo,accion)
                    break;

                case 'patente':
                    let data_2 = await CrudIntegrated({campo:campo, accion:accion, data:AddPatente, id:id})
                    if(data_2) HandleReloadData(campo,accion)
                    break;

                case 'trayecto':
                    let data_3 = await CrudIntegrated({campo:campo, accion:accion, data:AddTrayecto, id:id})
                    if(data_3) HandleReloadData(campo,accion)
                    break;
            
                default:
                    break;
            }
        }
        if(accion === "delete"){
            // console.log('in delete');
            let confirmar = confirm('Esta seguro de eliminar el item seleccionado')
            if(confirmar){
                switch (campo) {
                    case 'conductor':
                        let data_4 = await CrudIntegrated({campo:campo, accion:accion, data:null, id:id})
                        if(data_4) HandleReloadData(campo,accion)
                        break;
    
                    case 'patente':
                        let data_5 = await CrudIntegrated({campo:campo, accion:accion, data:null, id:id})
                        if(data_5) HandleReloadData(campo,accion)
                        break;
    
                    case 'trayecto':
                        let data_6 = await CrudIntegrated({campo:campo, accion:accion, data:null, id:id})
                        if(data_6) HandleReloadData(campo,accion)
                        break;
                
                    default:
                        break;
                }
            }else return
        }
    }
    
    window.addEventListener("resize",GetAnchoTabla)

    const GetListOfDatasCrud = async () => {
        const conductores = await ConductoresList();
        setList_Conductor(conductores);
        const patentes = await PatentesList();
        setList_Patente(patentes);
        const trayectos = await TrayectosList();
        setList_Trayecto(trayectos);
    }

    const SetChangeDate = async (evento,valor) => {
        //2024-02-12T23:59:00.000Z
        let hasta = 0
        let desde = 0
        // console.log(evento);
        if(evento === "hasta"){
            hasta = await Math.floor((new Date(valor+"T23:59:00.000Z")).getTime()/1000)
            // console.log(evento, hasta);
            setendtDate(hasta)
        }
        else if(evento === "desde"){
            desde = await Math.floor((new Date(valor+"T00:01:00.000Z")).getTime()/1000)
            // console.log(evento, desde);
            setStartDate(desde)
        }
    }

    /** genera el reprote con el rango de fechas seleccionadas */
    const GenerateReportNow = async () => {
        // console.log('Generar Reprote',startDate, endtDate)
        let isFileCreate = await MakeMeReport({inicio:startDate, fin: endtDate,empresa: LoginState.planta})
        if(isFileCreate){
            setModalShowHid(false)
            setStartDate(0)
            setendtDate(0)
        }
    }

    useEffect(() => {
      GetAnchoTabla()
      GetListOfDatasCrud()
    
      return () => {
        GetAnchoTabla()
        GetListOfDatasCrud()
      }
    }, [])

    useEffect(() => {
        // console.log(startDate, endtDate);
        if(startDate > 0 && endtDate > 0){
            if(startDate>endtDate){
                alert('Verifique el rango de fechas, la fecha inicial NO puede ser MAYOR a la FECHA FINAL DEL RANGO')
                setValiDate(true)
            }else{
                setValiDate(false)
            }
            if(startDate === 0 || endtDate === 0){
                setValiDate(true)
            }
        }
        if(startDate === 0 && endtDate === 0){
            setValiDate(true)
        }
    
      return () => {
        
      }
    }, [startDate, endtDate])
    
    
    return (
        <Fragment>
            <HeaderMenu Pag_Sel={'mantenedor'} />
            <div className='container-fluid mb-5'>
                <div className='row'>
                    <div className='col-12'>
                        <button className='btn btn-sm btn-info' onClick={()=>setModalShowHid(true)}>Generar Reporte de los Datos capturados por APP</button>
                    </div>
                    <div className='col-12 col-md-12 col-lg-4 mt-3'>
                        <div className='row modeling-tablescrud'>
                            <div className='col-12 text-center mb-3'>
                                <b>Conductor</b>
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-sm btn-info w-100 mb-2' onClick={()=>ShowHidenCampoAdd('conductor')}><PostAddIcon /> Agregar Conductor</button>
                                <div className='campo-add-data' style={{width: TablaWidth, display: CampoAddConductor?'block':'none'}}>
                                    <div className='row'>
                                        <div className='col-12 my-2'>
                                            <TextField
                                                id="outlined-basic"
                                                size='small'
                                                fullWidth label="Ingresar Conductor"
                                                variant="outlined"
                                                placeholder='Nombre del Conductor'
                                                value={AddConductor}
                                                onChange={(e)=>setAddConductor(e.target.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className='col-12 text-right'>
                                            <button
                                                className='btn btn-sm btn-danger'
                                                onClick={()=>ShowHidenCampoAdd('conductor')}
                                            >Cancelar</button>
                                            <span className='mx-2'></span>
                                            <button className='btn btn-sm btn-success' disabled={AddConductor.length<=3?true:false}
                                                onClick={()=>HandleAddDelete('conductor','add',null)}
                                            >Agregar</button>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm table-hover table-bordered">
                                    <thead>
                                        <tr>
                                        <th scope="col">Nombre Conductor</th>
                                        <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            List_Conductor.map(lst=><tr key={lst._id}>
                                            <td>{lst.nombre}</td>
                                            <td className='text-center'>
                                                <span onClick={()=>HandleAddDelete('conductor','delete',lst._id)} className='cursor'>
                                                    <DeleteForeverIcon color='error' />
                                                </span>
                                            </td>
                                        </tr>)
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-4 mt-3'>
                        <div className='row modeling-tablescrud'>
                            <div className='col-12 text-center mb-3'>
                                <b>Patente</b>
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-sm btn-info w-100 mb-2' onClick={()=>ShowHidenCampoAdd('patente')}><PostAddIcon /> Agregar Patente</button>
                                <div className='campo-add-data' style={{width: TablaWidth, display: CampoAddPatente?'block':'none'}}>
                                    <div className='row'>
                                        <div className='col-12 my-2'>
                                            <TextField
                                                id="outlined-basic"
                                                size='small'
                                                fullWidth label="Ingresar Patente"
                                                variant="outlined"
                                                placeholder='AABB-12'
                                                value={AddPatente}
                                                onChange={(e)=>setAddPatente(e.target.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className='col-12 text-right'>
                                            <button
                                                className='btn btn-sm btn-danger'
                                                onClick={()=>ShowHidenCampoAdd('patente')}
                                            >Cancelar</button>
                                            <span className='mx-2'></span>
                                            <button className='btn btn-sm btn-success' disabled={AddPatente.length<=3?true:false}
                                                onClick={()=>HandleAddDelete('patente','add',null)}
                                            >Agregar</button>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm table-hover table-bordered" id='tb_02'>
                                    <thead>
                                        <tr>
                                        <th scope="col">Placa Patente</th>
                                        <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            List_Patente.map(llst=><tr key={llst._id}>
                                                <td>{llst.placa}</td>
                                                <td className='text-center'>
                                                    <span onClick={()=>HandleAddDelete('patente','delete',llst._id)} className='cursor'>
                                                        <DeleteForeverIcon color='error' />
                                                    </span>
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-4 mt-3'>
                        <div className='row modeling-tablescrud'>
                            <div className='col-12 text-center mb-3'>
                                <b>Trayecto</b>
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-sm btn-info w-100 mb-2' onClick={()=>ShowHidenCampoAdd('trayecto')}><PostAddIcon /> Agregar Trayecto</button>
                                <div className='campo-add-data' style={{width: TablaWidth, display: CampoAddTrayecto?'block':'none'}}>
                                    <div className='row'>
                                        <div className='col-12 my-2'>
                                            <TextField
                                                id="outlined-basic"
                                                size='small'
                                                fullWidth label="Ingresar Trayecto"
                                                variant="outlined"
                                                placeholder='Nombre del Trayecto'
                                                value={AddTrayecto}
                                                onChange={(e)=>setAddTrayecto(e.target.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className='col-12 text-right'>
                                            <button
                                                className='btn btn-sm btn-danger'
                                                onClick={()=>ShowHidenCampoAdd('trayecto')}
                                            >Cancelar</button>
                                            <span className='mx-2'></span>
                                            <button className='btn btn-sm btn-success' disabled={AddTrayecto.length<=3?true:false}
                                                onClick={()=>HandleAddDelete('trayecto','add',null)}
                                            >Agregar</button>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm table-hover table-bordered">
                                    <thead>
                                        <tr>
                                        <th scope="col">Nombre Trayecto</th>
                                        <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            List_Trayecto.map(lllst=><tr key={lllst._id}>
                                                <td>{lllst.nombre}</td>
                                                <td className='text-center'>
                                                    <span onClick={()=>HandleAddDelete('trayecto','delete',lllst._id)} className='cursor'>
                                                        <DeleteForeverIcon color='error' />
                                                    </span>
                                                </td>
                                            </tr>)
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={ModalShowHid?'modal-mntdr_on d-flex  align-items-center':'modal-mntdr_off'}>
                        <div className='container modal-mntdr-cntr'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span className='lead'>Generar Reporte</span>
                                </div>
                                <div className='col-12 pt-3 col-md-6'>
                                    <span className=''>Desde</span>
                                    <input
                                        value={startDate===0?0:new Date(startDate*1000).toISOString().split("T")[0]}
                                        onChange={(e)=>SetChangeDate('desde', e.target.value)}
                                        type='date'
                                        className='charki-datepicker'
                                        max={new Date().toISOString().split("T")[0]}/>
                                </div>
                                <div className='col-12 pt-3 col-md-6'>
                                    <span className=''>Hasta</span>
                                    <input
                                        value={endtDate===0?0:new Date(endtDate*1000).toISOString().split("T")[0]}
                                        onChange={(e)=>SetChangeDate('hasta', e.target.value)}
                                        type='date'
                                        className='charki-datepicker'
                                        max={new Date().toISOString().split("T")[0]}/>
                                </div>
                                <div className='col-12 pt-3 text-right'>
                                    <button
                                        className='btn btn-sm btn-danger'
                                        onClick={()=>{
                                                setModalShowHid(false)
                                                setStartDate(0)
                                                setendtDate(0)
                                            }}>Cancelar</button>
                                    <button
                                        disabled={ValiDate}
                                        className='btn btn-sm btn-success ml-3'
                                        onClick={GenerateReportNow}
                                        >Generar Reporte</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
