import React, { useEffect, useState } from 'react'
import { Button, FormControl, Input, InputAdornment, InputLabel, MenuItem, TextField, Tooltip, Zoom } from '@mui/material'
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PasswordIcon from '@mui/icons-material/Password';

import { useNavigate } from 'react-router-dom';
import { GetRutData } from '../../api/GetRutData';
import { GetPlantas } from '../../api/GetPlantas.api';
import { AddAccount } from '../../api/AddAccount.api';

const CrearCuenta = () => {
  const navigate = useNavigate();

  const [valores, setValores] = useState({
    planta:'',
    clave: '',
    rut: '',
    email:'',
    nombre: '',
    ceco: '',
    cargo: '',
    isSubmit: false,
    errorMessage: null
  })

  const [isRut, setIsRut] = useState(false);
  const [equalsEmail, setEqualsEmail] = useState('')
  const [ListEMP, setListEMP] = useState([])
  const [ValidatePass, setValidatePass] = useState(false)

  const HandleSetData = async (e) => {
    setValores({...valores,[e.target.name]:e.target.value})
  }

  const formatRut = async (e) => {
    var clean = e.target.value.replace(/-/g,"")
    var largo = clean.length
    var prin = clean.substr(0,(largo-1));
    var nal = clean.substr((largo-1),largo)
    let data = prin+"-"+nal
    setValores({
      ...valores,
      ['rut']: data
    })
  }

  const verifiRut = async () => {
    if(valores.rut.length >=9){
      let retrn = await GetRutData(valores.rut)
      if(retrn){
        // console.log('ENCONTRADO', retrn);
        setValores({
          ...valores,
          nombre: retrn[0].nom_corto,
          ceco: retrn[0].ceco,
          cargo: retrn[0].cargo
        });
        // setIsRut(true);
      }else{
        // setIsRut(false);
      }
    }
    // toggleDrawer(false);
  }

  useEffect(() => {
    if(valores.email === equalsEmail
      && valores.email.length > 15
      && valores.clave.length>=8
      && valores.rut.length>=9
      && valores.nombre.length>=6
      ){
      let incluy = valores.email.includes('@')
      if(incluy){
        if(valores.email.split('@')[1] !== 'ariztia.com')setIsRut(false)
        else setIsRut(true)
      }
    }else setIsRut(false)
  }, [valores.email, equalsEmail, valores.clave, valores.rut, valores.nombre])  

  const GetEmpresasList = async () => {
    let listPlant = await GetPlantas()
    setListEMP(listPlant)
  }
  useEffect(() => {
    GetEmpresasList()
  }, [])
  
  useEffect(() => {
    let largo = valores.clave.length
    if(largo>=8)setValidatePass(true)
    else setValidatePass(false)
  }, [valores.clave])
  
  
  const HandleSubmit = async () => {
    let makeAccount = await AddAccount({valores})
    // console.log(makeAccount)
    if(makeAccount.success){
      alert('La Cuenta fue Creada con Exito')
      navigate('../')
    }else{
      setValores({
        ...valores,
        errorMessage: makeAccount.error
      })
    }
  }

  return (
    <>
      <div className="container-fluid bg-ariztia ">
        <div className="row" style={{height: '100vh'}}>
          <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
            <img src="/img/logo_ariztia.png" alt="Ariztia" />
          </div>
          <div className="col-12 col-md-6 px-3 d-flex align-items-center text-center justify-content-center performance-cc " >
            <div className="row">
              <div className="col-12 text-left">
                <h4>Crear Cuenta</h4>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Rut
                  </InputLabel>
                  <Input
                    type="text"
                    name='rut'
                    value={valores.rut}
                    onChange={(e)=>formatRut(e)}
                    onKeyUp={verifiRut}
                    startAdornment={
                      <InputAdornment position="start">
                        <ManageAccountsOutlinedIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Nombre Apellido
                  </InputLabel>
                  <Input
                    type="text"
                    name='nombre'
                    value={valores.nombre}
                    onChange={(e)=>HandleSetData(e)}
                    // focused={isRut}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Contraseña
                  </InputLabel>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="Minimo 8 caracteres, NO utilice la misma clave que usa en su Computador."
                    placement="top-start">
                  <Input
                    type="password"
                    name='clave'
                    value={valores.clave}
                    color={ValidatePass?'success':'error'}
                    onChange={(e)=>HandleSetData(e)}
                    // focused={isRut}
                    startAdornment={
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    }
                  /></Tooltip>
                </FormControl>
              </div>
              <div className="col-12 pt-2">
                <TextField
                  fullWidth
                  select
                  label="Seleccione Planta"
                  variant="standard"
                  name='planta'
                  value={valores.planta}
                  onChange={(e)=>HandleSetData(e)}
                >
                  {ListEMP.map((option) => (
                    <MenuItem key={option.NOMBRE} value={option.NOMBRE}>{option.NOMBRE}</MenuItem>
                  ))
                  } 
                </TextField>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Correo Electronico
                  </InputLabel>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="Solo permite Correos @ariztia.com"
                    placement="top-start">
                    <Input
                      style={{color: isRut?'green':'red'}}
                      type="email"
                      name='email'
                      value={valores.email}
                      onChange={(e)=>HandleSetData(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          <AlternateEmailTwoToneIcon />
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                </FormControl>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Verifique su Correo Electronico
                  </InputLabel>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="Solo permite Correos @ariztia.com"
                    placement="top-start">
                    <Input
                      style={{color: isRut?'green':'red'}}
                      type="email"
                      value={equalsEmail}
                      onChange={(e)=>setEqualsEmail(e.target.value)}
                      color={isRut?'success':'error'}
                      startAdornment={
                        <InputAdornment position="start">
                          <AlternateEmailTwoToneIcon />
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                </FormControl>
              </div>
              
              <div className="col-12 pt-3 text-right">
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={()=>HandleSubmit()}
                  disabled={!isRut}
                  endIcon={<SendTwoToneIcon />} >Crear Cuenta</Button>
              </div>
              <div className="col-12">
                {valores.errorMessage?
                <small className="text-danger">{valores.errorMessage}</small>
                :null
                }
              </div>
              <div className='col-12 pt-3'>
                <Button
                  variant='outlined'
                  onClick={()=>navigate('../')}
                >
                  <ReplyAllOutlinedIcon /> <span className='pl-3'></span>Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CrearCuenta