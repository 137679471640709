import React from 'react'
import { URL_CTRL_TRASP } from '../router/Url';

export const CallTheRealTime = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(URL_CTRL_TRASP+'get_the_time')

        const reqkkst = await query.json();
        // console.log('el rutttt=>>>>>>>',reqkkst)
        if(reqkkst.success){
          resolve({minuto:reqkkst.minutos,hora:reqkkst.horas,fecha:reqkkst.time, timePure: reqkkst.timePure})
        }else{
          let fecha__ = new Date()

          resolve({minuto:fecha__.getMinutes(), hora:fecha__.getHours(), time:fecha__, timePure: fecha__})
        }
      } catch (error) {
        let fecha__ = new Date()

        resolve({minuto:fecha__.getMinutes(), hora:fecha__.getHours(), time:fecha__, timePure: fecha__})
      }
  })
}
