import { useEffect, useLayoutEffect, useState } from 'react';
import '../App.css';
import { FaEraser } from "react-icons/fa";
import { FaPaste } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa";
import { FaListOl } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DisplayMap from '../maps/DispayMap'
// clock
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { SaveTurnoTransporte } from '../api/SaveTurnoTransporte.api';
import { GetUserByRut } from '../api/GetUserByRut.api';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderMenu } from './HeaderMenu';
import { EditTrasnporte } from '../api/EditTrasnporte.api';
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export const EditarDataTrasnporte = () => {
  const navigation = useNavigate();
  const {state} = useLocation();
  // console.log("data", state)

  const [LatLong, setLatLong] = useState('');//contenedor para latitud y longitud
  const [HereAdres, setHereAdres] = useState('');//contenedor para al direccion obtenida mediante las coordenadas atravez de tom tom

  const [showHidden, setshowHidden] = useState(true)//oculta o muestara el contenedor del mapa(esta accion no resetea el mapa)
  const [IsRutOk, setIsRutOk] = useState(false)//valida si encuentra el rut en la base de datos
  
  const [semana, setN_Week] = useState([]);

  const [aidi, setAidi] = useState(state._id)

  const [Week, setWeek] = useState({
    "lunes":state.lunes,
    "martes":state.martes,
    "miercoles":state.miercoles,
    "jueves":state.jueves,
    "viernes":state.viernes,
    "sabado":state.sabado,
    "domingo":state.domingo,
  })

  const [Rut, setRut] = useState(state.rut)

  const [Datos, setDatos] = useState({
    "nombre":state.nombre,
    "telefono":state.telefono,
    "georef":state.georef,
    "direccion":state.direccion,
    "semana":state.semana,
    "fecha":state.fecha
  })

  const [DayOfWeek, setDayOfWeek] = useState({
    "lun":"",
    "mar":"",
    "mie":"",
    "jue":"",
    "vie":"",
    "sab":"",
    "dom":"",
  })

  const [IsValidate, setIsValidate] = useState(true)

  const HandleShowHidden = async () => {
    setshowHidden(!showHidden)
  }

  const ParseRut = async (e) => {
    if(e.target.value.length <= 10){
      // console.log(e.target.value)
      var clean = e.target.value.replace(/-/g,"")
      var largo = clean.length
      var prin = clean.substr(0,(largo-1));
      var nal = clean.substr((largo-1),largo)
      let rutPaser = prin+"-"+nal
      await setRut(rutPaser)
    }
  }

  const FindRutData = async (rutPaser) => {
    if(rutPaser.length >= 9){
      // console.log(rutPaser)
      let resRut = await GetUserByRut({rutPaser})
      if(resRut){
        await setDatos({...Datos,['nombre']:resRut.nombre})
        await setIsRutOk(true)
      }else{
        setDatos({...Datos,['nombre']:""})
        await setIsRutOk(false)
      }
    }
  }

  const HandleCloneTime = async () => {
    const lunes_ = Week.lunes
    setWeek({
      "lunes":lunes_,
      "martes":lunes_,
      "miercoles":lunes_,
      "jueves":lunes_,
      "viernes":lunes_,
      "sabado":lunes_,
      "domingo":lunes_,
    })
  }
  
  const ParseFone = async () => {
    let fone = Datos.telefono

      if(fone.length===9){
        // console.log(fone)
        setDatos({...Datos,["telefono"]:fone.substring(0,1)+" "+fone.substring(1,5)+" "+fone.substring(5,9)})
      }
  }

  const SaveDataOnSRV = async () => {
    Datos["rut"]=Rut
    Datos["lunes"]=Week.lunes
    Datos["martes"]=Week.martes
    Datos["miercoles"]=Week.miercoles
    Datos["jueves"]=Week.jueves
    Datos["viernes"]=Week.viernes
    Datos["sabado"]=Week.sabado
    Datos["domingo"]=Week.domingo
    // console.log(Datos, aidi)
    const responde = await EditTrasnporte({Datos, aidi})
    if(responde.success){
      alert(responde.body.msg)
      navigation('/ingresar_transporte',{replace: true})
    }else{
      alert(responde.body.msg)
    }
  }

  useEffect(() => {
    // FindRutData(Rut)
  }, [Rut])

  useEffect(() => {
    setDatos({...Datos,
      "georef": LatLong===""?state.georef:LatLong,
      "direccion":HereAdres===""?state.direccion:HereAdres
    })
  }, [LatLong,HereAdres])
  
  useEffect(() => {
    ParseFone()
  }, [Datos.telefono])
  
  useEffect(() => {
    // -2
    setN_Week([
      // {
      //   "week": dayjs(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).week(),
      //   "fecha": new Date(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).toISOString().split('T',1)[0]
      // },
      {
        "week": dayjs(dayjs().day(1).$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).$d).toISOString().split('T',1)[0]+" - "+new Date(dayjs(dayjs().day(7).$d).$d).toISOString().split('T',1)[0]
      },{
        "week": dayjs(dayjs(dayjs().day(1).$d).add(1, 'week').$d).week(),
        "fecha": new Date(dayjs(dayjs().day(1).$d).add(1, 'week').$d).toISOString().split('T',1)[0]+" - "+new Date(dayjs(dayjs().day(7).$d).add(1, 'week').$d).toISOString().split('T',1)[0]
      }
      // ,{
      //   "week": dayjs(dayjs(dayjs().day(1).$d).add(2, 'week').$d).week(),
      //   "fecha": new Date(dayjs(dayjs().day(1).$d).add(2, 'week').$d).toISOString().split('T',1)[0] 
      // }
    ])

  }, [])

  useEffect(() => {
    let key_ = Object.keys(Datos)
    setIsValidate(false) 
    for (const ittm of key_) {
      if(Datos[ittm] === "") setIsValidate(true)
    }
  }, [Datos])

  useLayoutEffect(() => {
    HandleShowHidden()
  }, [])

  useEffect(() => {
    if(dayjs(dayjs().day(1).$d).week()===Datos.semana){
      // console.log(Datos.semana,"=>",new Date(dayjs(dayjs().day(1).$d).$d).toISOString().split('T',1)[0]);
      setDayOfWeek({
        "lun":new Date(dayjs(dayjs().day(1).$d).$d).toISOString().split('T',1)[0],
        "mar":new Date(dayjs(dayjs().day(2).$d).$d).toISOString().split('T',1)[0],
        "mie":new Date(dayjs(dayjs().day(3).$d).$d).toISOString().split('T',1)[0],
        "jue":new Date(dayjs(dayjs().day(4).$d).$d).toISOString().split('T',1)[0],
        "vie":new Date(dayjs(dayjs().day(5).$d).$d).toISOString().split('T',1)[0],
        "sab":new Date(dayjs(dayjs().day(6).$d).$d).toISOString().split('T',1)[0],
        "dom":new Date(dayjs(dayjs().day(7).$d).$d).toISOString().split('T',1)[0],
      })
    }
    if(dayjs(dayjs(dayjs().day(1).$d).add(1, 'week').$d).week()===Datos.semana){
      // console.log(Datos.semana,"=>",new Date(dayjs(dayjs().day(1).$d).add(1, 'week').$d).toISOString().split('T',1)[0])
      setDayOfWeek({
        "lun":new Date(dayjs(dayjs().day(1).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "mar":new Date(dayjs(dayjs().day(2).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "mie":new Date(dayjs(dayjs().day(3).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "jue":new Date(dayjs(dayjs().day(4).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "vie":new Date(dayjs(dayjs().day(5).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "sab":new Date(dayjs(dayjs().day(6).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
        "dom":new Date(dayjs(dayjs().day(7).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
      })
    }
  }, [Datos.semana])

  return (
    <>
      <HeaderMenu Pag_Sel={'buscar'}/>
      <div className='container-fluid mt-2 mb-3'>
        <div className='row'>
        <div className='col-12 col-md-4'>
          <div className="form-group performance-2">
            <label htmlFor="exampleInputEmail1">* Rut</label>
            <input type="text" name='rut' value={Rut} onChange={(e)=>ParseRut(e)} readOnly={true} className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingrese Rut" autoComplete='off' />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className="form-group performance-2">
            <label>* Nombre</label>
            <input type="text" className="form-control form-control-sm" readOnly={true} name='nombre' value={Datos.nombre} placeholder="Ingrese Nombre" autoComplete='off' />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className="form-group performance-2">
            <label>* Telefono</label>
            <input type="tel" name='telefono' value={Datos.telefono} className="form-control form-control-sm" onChange={(e)=>setDatos({...Datos, [e.target.name]:e.target.value})} placeholder="9 1234 5678" autoComplete='off'/>
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>

        <div className='col-12' >
          <button
            className='btn btn-success btn-sm my-2'
            onClick={()=>HandleShowHidden()}>
              {showHidden?'Ocultar Mapa':'Mostrar Mapa'}
          </button>
        </div>

          <div className={showHidden?'col-12':'isNotShow'} >
            <DisplayMap setLatLong={setLatLong} setHereAdres={setHereAdres} />
          </div>
          <div className='col-12 col-md-4'>
            <div className="form-group performance-2">
              <label>* Georeferencia</label>
              <input type="text" value={Datos.georef} className="form-control form-control-sm" id="exampleInputEmail1" readOnly placeholder="Georeferencia" />
              {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
            </div>
          </div>
          <div className='col-12 col-md-8'>
            <div className="form-group performance-2">
              <label>* Direccion</label>
              <input type="text" name='direccion' value={Datos.direccion} onChange={(e)=>setDatos({...Datos,[e.target.name]:e.target.value})} className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Direccion" autoComplete='off' />
              {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
            </div>
          </div>
          <div className='col-12 py-2'>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <p className='h3'>Horarios</p>
              </div>
              <div className='col-7 col-md-5 pl-4'>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-simple-select-label">Semana-fecha</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Semana-fecha"
                    value={Datos.semana}
                    onChange={(e)=>{
                      let sm1 = semana.find(({week}) => week === e.target.value)
                      setDatos({...Datos,["semana"]:e.target.value, ["fecha"]:sm1.fecha})
                    }
                    }
                  >
                    {/* console.log(itm.week, itm.fecha) */}
                    {
                      semana.map(itm=><MenuItem key={itm.week} value={itm.week}>{itm.fecha}</MenuItem>)
                      // semana.map(itm=><MenuItem key={itm.week} value={itm.week}>{itm.week<10?"0"+itm.week:itm.week} - {itm.fecha}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-5 col-md-3'>
                <button className='btn btn-sm btn-warning float-right py-2' onClick={()=>HandleCloneTime()}><FaPaste /> Pegar Horario</button>
              </div>
            </div>
            
          </div>
          {/* horario por dia */}

          <div className='col-12'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'><span>Lunes</span><br/>{DayOfWeek.lun} <span className='ico-clone'><FaLayerGroup/></span></div>
                    <div className='col-12 col-md-8 py-2'>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.lunes===""?null:new Date(`2023-01-24 ${Week.lunes}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['lunes']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      name='lunes'
                      label="Horario"
                      value={Week.lunes}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>


                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["lunes"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Martes<br/>{DayOfWeek.mar}</div>
                    <div className='col-12 col-md-8 py-2'>
                     
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.martes===""?null:new Date(`2023-01-24 ${Week.martes}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['martes']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      name="martes"
                      label="Horario"
                      value={Week.martes}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["martes"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Miercoles<br/>{DayOfWeek.mie}</div>
                    <div className='col-12 col-md-8 py-2'>
                      
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.miercoles===""?null:new Date(`2023-01-24 ${Week.miercoles}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['miercoles']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="miercoles"
                      label="Horario"
                      value={Week.miercoles}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["miercoles"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Jueves<br/>{DayOfWeek.jue}</div>
                    <div className='col-12 col-md-8 py-2'>
                      
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.jueves===""?null:new Date(`2023-01-24 ${Week.jueves}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['jueves']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="jueves"
                      label="Horario"
                      value={Week.jueves}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["jueves"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Viernes<br/>{DayOfWeek.vie}</div>
                    <div className='col-12 col-md-8 py-2'>
                      
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.viernes===""?null:new Date(`2023-01-24 ${Week.viernes}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['viernes']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="viernes"
                      label="Horario"
                      value={Week.viernes}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["viernes"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Sabado<br/>{DayOfWeek.sab}</div>
                    <div className='col-12 col-md-8 py-2'>
                      
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.sabado===""?null:new Date(`2023-01-24 ${Week.sabado}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['sabado']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="sabado"
                      label="Horario"
                      value={Week.sabado}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["sabado"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
                <div className='col-12 performance'>
                  <div className='row'>
                    <div className='col-12 col-md-4 center'>Domingo<br/>{DayOfWeek.dom}</div>
                    <div className='col-12 col-md-8 py-2'>
                      
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <TimePicker
                        ampm={false}
                        label="Horario Entrada"
                        value={Week.domingo===""?null:new Date(`2023-01-24 ${Week.domingo}` )}
                        onChange={(newValue) => {
                          setWeek({...Week,['domingo']:(newValue.$H<10?"0"+newValue.$H:newValue.$H)+":"+(newValue.$m<10?"0"+newValue.$m:newValue.$m)});
                        }}
                        renderInput={(params) => <TextField {...params} size={'small'} />}
                      />
                    </LocalizationProvider> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size='small'>Horario</InputLabel>
                    <Select
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="domingo"
                      label="Horario"
                      value={Week.domingo}
                      onChange={(e)=>setWeek({...Week,[e.target.name]:e.target.value})}
                    >
                      <MenuItem value={"04:00 hrs"}>04:00 hrs</MenuItem>
                      <MenuItem value={"07:00 hrs"}>07:00 hrs</MenuItem>
                      <MenuItem value={"12:00 hrs"}>12:00 hrs</MenuItem>
                      <MenuItem value={"13:00 hrs"}>13:00 hrs</MenuItem>
                      <MenuItem value={"15:00 hrs"}>15:00 hrs</MenuItem>
                      <MenuItem value={"23:15 hrs"}>23:15 hrs</MenuItem>
                      <MenuItem value={"23:45 hrs"}>23:45 hrs</MenuItem>
                      <MenuItem value={"Dia Libre"}>Dia Libre</MenuItem>
                      <MenuItem value={"Licencia Medica"}>Licencia Medica</MenuItem>
                      <MenuItem value={"Movilizacion Propia"}>Movilizacion Propia</MenuItem>
                      <MenuItem value={"Vacaciones"}>Vacaciones</MenuItem>
                    </Select>
                  </FormControl>

                    </div>
                    {/* <div className='col-2 center pt-2'>
                      <button className='btn btn-danger btn-sm' onClick={()=>setWeek({...Week,["domingo"]:""})}><FaEraser /></button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
