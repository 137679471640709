import { URL_CTRL_TRASP, URL_SRV_RAIZ } from '../router/Url';

export const MakeAFile = () => {
  
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_TRASP+'make_a_file',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.success){
      resolve(responsito.body)
      let url = responsito.body;
      let a = document.createElement("a");
      a.href = URL_SRV_RAIZ+url;//to web add url_server+build/
      // console.log(a.href)
      // a.href = url;
      a.download = "transporte.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      alert('En instantes se descargara su Archivo')

    }else{
      resolve(responsito)
      alert('ups!! hay un error vuelva a intentarlo.')
    }
  })
}
