import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu';

import AttachmentIcon from '@mui/icons-material/AttachFile';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import BackupIcon from '@mui/icons-material/Backup';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { Button } from '@mui/material';
import { URL_CTRL_TRASP, URL_SRV_RAIZ } from '../../router/Url';
import CancelIcon from '@mui/icons-material/Cancel';

import { Phone } from '../../component/Phone.comp';
import { ChangeAddrreess } from './ChangeAddrreess.page';
import { ChangeAddrreesscomp } from './ChangeAddrreesscomp.page';
import { useNavigate } from 'react-router-dom';
import { Component_noDress } from './Component_noDress';
import LoginContext from '../../context/login_context/LoginContext';
import dayjs from 'dayjs'
import { CallTheRealTime } from '../../api/CallTheRealTime.api';
var weekOfYear = require('dayjs/plugin/weekOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs().tz('America/Santiago')
let formater = new Intl.DateTimeFormat('es-CL', {timeZone: 'America/Santiago'})
let notNow = new Date()
// notNow.setDate(notNow.getDate()+7)
let date_static = notNow.setHours(16,0,0,0);
let date_static_friday = notNow.setHours(12,0,0,0);
let date_static_online = notNow.setHours(7,0,0,0);

let formatFecha = formater.format(date_static)

export const CargaMasiva = () => {//init component

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  
  const navigate = useNavigate();

  const [File_, setFile_] = useState('');
  const [Archivo, setArchivo] = useState('')
  const [Charging, setCharging] = useState(false)
  const [semana, setN_Week] = useState({
    desde: '',
    hasta: ''
  });

  const [fecha, setFecha] = useState('');
  const [duplicados, setDuplicados] = useState([]);
  const [Dupli_NoAuth, setDupli_NoAuth] = useState([]);
  const [No_phone, setNo_phone] = useState([]);
  const [Agregados_list, setAgregados_list] = useState([]);
  const [Nodress, setNodress] = useState([]);
  const [isCompleteForm, setisCompleteForm] = useState(true);
  const [IsHiddedn, setIsHiddedn] = useState(true);
  const [activate, setActivate] = useState(false);
  const [IsActiveBanner, setIsActiveBanner] = useState(true);
  const [shoHidCarga, setshoHidCarga] = useState(true);


  const HandlePreloadFile = async (e) => {
    setArchivo(e.target.files[0]);
    setFile_(e.target.value.split('\\').pop());
    // console.log(e.target.files[0])
  }

  const HandleUploadFile = async () => {
    setisCompleteForm(true)
    setDuplicados([]);
    setNodress([]);
    setNo_phone([]);
    setAgregados_list([]);
    setDupli_NoAuth([]);

    await setCharging(true)
    const formData = new FormData();
    formData.append('ARCHIVO',Archivo);
    formData.append('FECHA',fecha);
    formData.append('USER',LoginState.user);
    formData.append('MAIL',LoginState.mail);
    const fetc = await fetch(URL_CTRL_TRASP+'uploadmasiveass', {
      method: 'POST',
      headers: {
        'authorization': "marcuxo"
      },
      body: formData
    })
    let duplicidad = ''

    let recuest = await fetc.json();
    // console.log(recuest);
    let no_dress_arr = []
    recuest.body.nodress.forEach(ind => {
      ind['state'] = true
      no_dress_arr.push(ind)
    });
    // console.log(recuest.body.dupli_noAuth)
    //guarda los datos en variables locales y de entorno
    setDuplicados(recuest.body.duplicados);
    setNodress(no_dress_arr);
    setNo_phone(recuest.body.no_phone);
    setAgregados_list(recuest.body.agregados_list);
    setDupli_NoAuth(recuest.body.dupli_noAuth);

    sessionStorage.setItem('duplicado',JSON.stringify(recuest.body.duplicados))
    sessionStorage.setItem('noDress',JSON.stringify(no_dress_arr))
    sessionStorage.setItem('noPhone',JSON.stringify(recuest.body.no_phone))
    sessionStorage.setItem('agregadosList',JSON.stringify(recuest.body.agregados_list))
    sessionStorage.setItem('Dupli_NoAuth',JSON.stringify(recuest.body.dupli_noAuth))

    

    recuest.body.duplicados.forEach(elmt => {
      duplicidad += ".- "+elmt.rut+" "+elmt.turno+"\r"
    });
    if(recuest.success){
      // console.log(recuest.body.duplicados.length)
      let comentario = ''
      if(recuest.body.agregados > 0)comentario += `Se Agregaron ${recuest.body.agregados} solicitudes de transporte\r`
      if(recuest.body.duplicados.length > 0)comentario += `Se encontraron ${recuest.body.duplicados.length} con Transporte cargado\r`
      if(recuest.body.nodress.length > 0)comentario += `Se encontraron ${recuest.body.nodress.length} sin dirección asigada para Transporte\r`
      if(recuest.body.no_phone.length > 0)comentario += `Se encontraron ${recuest.body.no_phone.length} sin numero de Telefono inscrito\r`
      if(recuest.body.dupli_noAuth.length > 0)comentario += `Se encontraron ${recuest.body.dupli_noAuth.length} Solicitudes ya cargadas que NO se modificaron\r`
      setCharging(false)
      alert(comentario)
      setFile_('')
      setArchivo('')
      document.getElementById('file').value = ''
      // document.location.reload()
    }else{
      alert('Error al cargar los datos por favor inténtelo nuevamente.')
    }
  }

  // compara las fechaspara habiliatar o bloquear la plataforma
  async function CompareDateAndTime(fecha){
    
    let realTime = await CallTheRealTime()
    
    let DayLimit = '';
    let DayAntes = '';
    let a = new Date(fecha);
    let b = new Date(date_static);
    let c = new Date(date_static_friday);
    let wakeup = new Date(date_static_online);
    let hrsServ = realTime.hora
    // console.log(realTime)
    // console.log('num dia',dayjs().day(0)['$d'])
    // console.log(a.getHours(),b.getHours(),c.getHours())
    // console.log("================>",dayjs().isSame(dayjs().day(0), 'day'));
    if(dayjs().isSame(dayjs().day(0), 'day')){
      setIsHiddedn(false)
    }else{
      if(LoginState.area === "SSGG"){
        // console.log('SSGG')
        DayLimit = dayjs().day(0)
        DayAntes = dayjs().day(6)

        let antes = dayjs().isBefore(dayjs(DayLimit)['$d'])
        let isSame = dayjs().isSame(DayAntes, 'day')

        if(antes){
          if(isSame){
            if(hrsServ < b.getHours() && hrsServ >= wakeup.getHours()){
              // setIsHiddedn(true)//------------not bloked to SSGG
              // console.log('a')
            }else{
              setIsHiddedn(false)
              // console.log('b')
            }
          }else{
            // setIsHiddedn(true)//------------not bloked to SSGG
            // console.log('c')
          }
        }else{
            // setIsHiddedn(true)//------------not bloked to SSGG
            // console.log('e')
        }
      }else{
        DayLimit = dayjs().day(6)
        DayAntes = dayjs().day(5)

        let antes = dayjs().isBefore(dayjs(DayLimit)['$d'])
        let isSame = dayjs().isSame(DayAntes, 'day')

        if(antes){
          if(isSame){
            if(hrsServ < c.getHours() && hrsServ >= wakeup.getHours()){
              setIsHiddedn(true)
              // console.log('f')
            }else{
              setIsHiddedn(false)
              // console.log('g')
            }
          }else{
            if(hrsServ < b.getHours() && hrsServ >= wakeup.getHours()){
              setIsHiddedn(true)
              // console.log('h')
            }else{
              setIsHiddedn(false)
              // console.log('i')
            }
          }
        }else{
            setIsHiddedn(false)
            // console.log('j')
        }
      }
    }
  }

  const Obtain_File_Example = async () => {
    let url = 'files/Template_Transporte.xlsx';
      let a = document.createElement("a");
      a.href = url;//to web add url_server+build/
      // a.href = url;
      a.download = "Template_transporte.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
  }

  const CloseBanner = async () => {
    sessionStorage.setItem('banner', false)
    setIsActiveBanner(false)
  }
  

  useEffect(() => {
    let notNow = new Date()
    let now_ = notNow.setHours(15,0,0,0)
    let desde = ''
    if(new Date().toLocaleString()<new Date(now_).toLocaleString()){
      desde = new Date().toISOString().split('T',1)[0]
    }else{
      let nexday = notNow.setDate(notNow.getDate()+1)

      desde = notNow.toISOString().split('T')[0]
    }
    setN_Week(
      // {
      //   "week": dayjs(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).week(),
      //   "fecha": new Date(dayjs(dayjs().day(1).$d).subtract(1, 'week').$d).toISOString().split('T',1)[0]
      // },
      {
        // "wee": dayjs(dayjs().day(1).$d).week(),
        "desde": desde,
        // "desde": new Date(dayjs(dayjs().day(1).$d).$d).toISOString().split('T',1)[0],
        "hasta": new Date(dayjs(dayjs().day(7).$d).$d).toISOString().split('T',1)[0]
      },
      // {
      //   "week": dayjs(dayjs(dayjs().day(1).$d).add(1, 'week').$d).week(),
      //   "desde": new Date(dayjs(dayjs().day(1).$d).add(1, 'week').$d).toISOString().split('T',1)[0],
      //   "hasta": new Date(dayjs(dayjs().day(7).$d).add(1, 'week').$d).toISOString().split('T',1)[0]
      // }
      // ,{
      //   "week": dayjs(dayjs(dayjs().day(1).$d).add(2, 'week').$d).week(),
      //   "fecha": new Date(dayjs(dayjs().day(1).$d).add(2, 'week').$d).toISOString().split('T',1)[0] 
      // }
    )
  }, [])

  useEffect(() => {
    // console.log(fecha, File_)
    if(File_===''){
      setisCompleteForm(true)
    }else{
      setisCompleteForm(false)
    }
  }, [File_, fecha])
  
  useEffect(() => {
    CompareDateAndTime(Date.now())
    let intervalo = setInterval(() => {
      // console.log('intervalo')
      CompareDateAndTime(Date.now())
    }, 10000);
    // intervalo()
    return () => {
      clearInterval(intervalo)
    } 
  }, [])

  // guarda losdatos en la memoria locar para cambiar de pagina sin perder los datos
  useEffect(() => {
    let duplicados_ = sessionStorage.getItem('duplicado');
    let noDress_ = sessionStorage.getItem('noDress');
    let noCell_ = sessionStorage.getItem('noPhone');
    let agregadosList_ = sessionStorage.getItem('agregadosList');
    let dupli_NoAuth_ = sessionStorage.getItem('Dupli_NoAuth');

    if(duplicados_){
      setDuplicados(JSON.parse(duplicados_))
    }
    if(noDress_){
      setNodress(JSON.parse(noDress_))
    }
    if(noCell_){
      setNo_phone(JSON.parse(noCell_))
    }
    if(agregadosList_){
      setAgregados_list(JSON.parse(agregadosList_))
    }
    if(dupli_NoAuth_){
      setDupli_NoAuth(JSON.parse(dupli_NoAuth_))
    }

    let banner = sessionStorage.getItem('banner')
    // console.log('banner', banner)
    if(banner===null){
      // console.log('null///')
      setIsActiveBanner(true)
      sessionStorage.setItem('banner', true)
    }
    if(banner==='false'){
      // console.log('false///')
      setIsActiveBanner(false)
      sessionStorage.setItem('banner', false)
    }
  }, [])

  return (
    <>
      <HeaderMenu Pag_Sel={'carga_masiva'} />
      <br/>
      <br/>

      {/* modulo carga de carga archivo */}
      {
        IsHiddedn?
        <div className='container mt-2'>
          <div className='row performance-2'>
            
            <div className='col-12 py-2'>
              <h5>Cargar Transporte</h5>
            </div>
            
            <div className='col-12 col-md-12 text-center pt-3 '>
              <div className='col-12'>
                <label htmlFor='file' id="secondfile" className={Charging?'btn btn-sm btn-info disabled':'btn btn-sm btn-info'}>{
                  File_===''? 
                  <>Seleccionar archivo <AttachmentIcon /></>
                  :<><Inventory2OutlinedIcon /> {File_}</>
                }</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  disabled={Charging}
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  required
                  onChange={(e)=>HandlePreloadFile(e)}
                />
              </div>
            </div>
            {/* <div className='col-12 col-md-6 text-center pt-3'>
              <div className="form-group">
                <div className='input-group date' id='datetimepicker1'>
                  <input type='date' value={fecha} onChange={(e)=>setFecha(e.target.value)} className="form-control" name='fecha_to_load' min={semana.desde} max={semana.max} />
                  
                </div>
            </div>
            </div> */}
            <div className='col-12 text-center pt-3'>
              <Button
                variant='contained'
                disabled={isCompleteForm}
                onClick={()=>HandleUploadFile()}
              >
                {
                  Charging?'Cargando archivo . . . ..':'Cargar Archivo' 
                }<FilePresentOutlinedIcon />
                  
              </Button>
            </div>
            <div className='col-12 text-right'>
              <a download={'Template_Transporte'} href='/file/Template_Transporte_new.xlsx'>Descargar ejemplo</a>
              {/* <p onClick={()=>Obtain_File_Example()} style={{cursor:'pointer'}}>Descargar ejemplo</p> */}
            </div>

            <div className='col-12 text-center'>
              <small className='text-danger'>Solo es posible cargar los transportes de lunes a jueves desde <b>08:00hrs</b> hasta las <b>15:00hrs</b></small><br/>
              <small className='text-danger'>y los Viernes desde <b>08:00hrs</b> hasta las <b>12:00hrs</b>.</small><br/>
              <small className='text-danger'>Tome en <b>consideración</b> estos horarios para <b>coordinar</b> los <b>transportes con tiempo</b>.</small>
            </div>
          </div>
        </div>
        :
        <div className='col-12 text-center'>
          <p className='lead'>La carga de solicitudes de transporte es:</p>
          <p className='lead'>De lunes a jueves de <span className='text-danger'><b>08:00hrs</b></span> hasta las <span className='text-danger'><b>15:00hrs</b></span></p>
          <p className='lead'>Los viernes de <span className='text-danger'><b>08:00hrs</b></span> hasta las <span className='text-danger'><b>12:00hrs</b></span></p>
        </div>
      }

      {/* lista los datos ingresados y cargados a la base de datos */}
      {
        Agregados_list.length && IsHiddedn?
        <div className='container my-3'>
          <div className='row'>
            <div className='col-12'>
              <span className='float-right'>
                <button
                  className='btn btn-sm btn-warning'
                  onClick={()=>setshoHidCarga(!shoHidCarga)}
                >
                  {shoHidCarga?'Ocultar':'Mostrar'}
                </button>
                
              </span>
              <h5>Solicitudes de transporte Cargadas</h5>
            </div>
            <div className={shoHidCarga?'col':'col ocultacion'}>
              <table className="table table-sm table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Rut</th>
                    <th>Turno</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Agregados_list.map((imt, index) =>
                    <tr key={index}>
                      <td><small>{imt.rut}</small></td>
                      <td><small>{imt.turno}</small></td>
                      <td><small>{imt.fecha}</small></td>
                    </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :null
      }

      {/* lista de los usuarios sin direccion asignada */}
      {
        Nodress.length && IsHiddedn?
        <div className='container my-3'>
          <div className='row'>
            <div className='col-12'>
              <h5>Usuario sin Dirección asignada</h5>
            </div>
            <div className='col'>
              <table className="table table-sm table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Rut</th>
                    <th>Turno</th>
                    <th>Fecha</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Nodress.map((imt, index) =>
                      <Component_noDress imt={imt} key={index} />
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :null
      }

      {/* lista de usuarios que no tienen numero de telefono asignado */}
      {
        No_phone.length && IsHiddedn?
        <div className='container my-3'>
          <div className='row'>
            <div className='col-12'>
              <h5>Usuarios sin Numero de Telefono</h5>
            </div>
            <div className='col'>
              <table className="table table-sm table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Rut</th>
                    <th>Nombre</th>
                    <th>Telefono</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    No_phone.map((imt) =>
                      <Phone Datos={imt} key={imt._id}/>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :null
      }
      
      {/* lista de usuarios con el turno ya cargado */}
      {
        duplicados.length && IsHiddedn?
        <div className='container my-3'>
          <div className='row'>
            <div className='col-12'>
              <h5>Usuarios con trasporte cargado y modificado</h5>
            </div>
            <div className='col'>
              <table className="table table-sm table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Rut</th>
                    <th>Turno</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    duplicados.map((imt, index) =>
                      <tr key={index}>
                        <td><small>{imt.rut}</small></td>
                        <td><small>{imt.turno}</small></td>
                        <td><small>{imt.fecha}</small></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :null
      }

      {/* lista de usuarios con el turno ya cargado */}
      {
        Dupli_NoAuth.length && IsHiddedn?
        <div className='container my-3'>
          <div className='row'>
            <div className='col-12'>
              <h5>Usuarios con trasporte ya cargado y <span className='text-danger'>No</span> Modificado</h5>
              <small className='text-danger'><b>Las siguientes solicitudes están bloqueadas para cambios, solo un administrador autorizado puede realizar los cambios.</b></small>
            </div>
            <div className='col'>
              <table className="table table-sm table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Rut</th>
                    <th>Turno</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Dupli_NoAuth.map((imt, index) =>
                      <tr key={index}>
                        <td><small>{imt.rut}</small></td>
                        <td><small>{imt.turno}</small></td>
                        <td><small>{imt.fecha}</small></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        :null
      }

     
      
    </>
  )
}
