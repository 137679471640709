import { Route, Routes } from 'react-router-dom';
import { EditarDataTrasnporte } from './component/EditarDataTrasnporte';
import { ProtectedRouter } from './component/Protected/ProtectedRouter';
import LoginState from './context/login_context/LoginState';
import { Login } from './pages/login/Login.page';
import CrearCuenta from './pages/makeAccount/CrearCuenta';
import RecoveryPass from './pages/recoveryPass/RecoveryPass';
import { CargaMasiva } from './pages/session/CargaMasiva.page';
import { ChangeAddrreess } from './pages/session/ChangeAddrreess.page';
import { ChangeAddrreessComp } from './pages/session/ChangeAddrreesscomp.page';
import { FindUserEdit } from './pages/session/FindUserEdit.page';
import { IngresarTransporte } from './pages/session/IngresarTransporte.page';
import { MantenedorAppDataGo } from './pages/session/MantenedorAppDataGo.page';


function App() {
  
  console.log('%c awesome apps for you by MarcoUrrutiaMolina ', 'background: #FF0000; color: #FFF000; font-size: 14px; font-weight: bolder');
  
  return (
      <LoginState>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/crear_cuenta' element={<CrearCuenta />} />
          <Route path='/recuperar_cuenta' element={<RecoveryPass />} />
          
          <Route path='/carga_masiva' element={
            <ProtectedRouter>
              <CargaMasiva />
            </ProtectedRouter>
          }/>

          {/* <Route path='/ingresar_transporte' element={
            <ProtectedRouter>
              <IngresarTransporte />
            </ProtectedRouter>
          }/> */}

          <Route path='/buscar' element={
            <ProtectedRouter>
              <FindUserEdit />
            </ProtectedRouter>
          }/>

          <Route path='/Editar' element={
            <ProtectedRouter>
              <EditarDataTrasnporte />
            </ProtectedRouter>
          }/>

          <Route path='/cambiar_direccion' element={
            <ProtectedRouter>
              <ChangeAddrreess />
            </ProtectedRouter>
          }/>

          <Route path='/mantenedor' element={
            <ProtectedRouter>
              <MantenedorAppDataGo />
            </ProtectedRouter>
          }/>

          <Route path='/direccion_link' element={
            <ProtectedRouter>
              <ChangeAddrreessComp />
            </ProtectedRouter>
          }/>

          <Route path='*' element={<Login />} />
        </Routes>

      </LoginState>
  );
}

export default App;
