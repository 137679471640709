import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetUserByRut } from '../../api/GetUserByRut.api'
import { HeaderMenu } from '../../component/HeaderMenu'
import DisplayMap from '../../maps/DispayMap'
import { URL_CTRL_TRASP } from '../../router/Url'
import LoginContext from '../../context/login_context/LoginContext'

// icono
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

export const ChangeAddrreess = () => {

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [Datos, setDatos] = useState({
    "nombre":"",
    "georef":"",
    "direccion":"",
  })
  const [Telefono, setTelefono] = useState('')
  const [IsValidate, setIsValidate] = useState(true)
  const [IsRutOk, setIsRutOk] = useState(false)//valida si encuentra el rut en la base de datos
  const [Rut, setRut] = useState('')
  const navigate = useNavigate();
  const [LoaddingData, setLoaddingData] = useState(false);
  const [TypeOfChange, setTypeOfChange] = useState('')

  const [LatLong, setLatLong] = useState('');//contenedor para latitud y longitud
  const [HereAdres, setHereAdres] = useState('');//contenedor para al direccion obtenida mediante las coordenadas atravez de tom tom


  const ParseRut = async (e) => {
    if(e.target.value.length <= 10){
      // console.log(e.target.value)
      var clean = e.target.value.toUpperCase().replace(/-/g,"")
      var largo = clean.length
      var prin = clean.substr(0,(largo-1));
      var nal = clean.substr((largo-1),largo)
      let rutPaser = prin+"-"+nal
      await setRut(rutPaser)
    }
  }

  const FindRutData = async (rutPaser) => {
    if(rutPaser.length >= 9){
      // console.log(rutPaser)
      let resRut = await GetUserByRut({rutPaser});
      // console.log(resRut)
      if(resRut.typo_==="qr"){
        await setDatos({
          ...Datos,
          ['nombre']:resRut.data_.nombre,
          ["georef"]:"",
          ["direccion"]:"",
        })
        await setTelefono("")
        await setIsRutOk(true)
        //AGREGAR
        setTypeOfChange('AGREGAR')
      }
      if(resRut.typo_==="transport"){
        await setDatos({...Datos,
          ['nombre']:resRut.data_.nombre,
          ["georef"]:resRut.data_.georef,
          ["direccion"]:resRut.data_.direccion
        })
        await setTelefono(resRut.data_.telefono)
        // await setshowHidden(false)

        await setIsRutOk(true)
        // MODIFICAR
        setTypeOfChange('MODIFICAR')
      }
      else{
        await setIsRutOk(false);
        await setDatos({...Datos,
          ['nombre']:""
        })
        // AGREGAR
        setTypeOfChange('AGREGAR')
      }
      // if(resRut){
      //   await setDatos({...Datos,['nombre']:resRut.nombre})
      //   await setIsRutOk(true)
      // }else{
      //   setDatos({...Datos,['nombre']:""})
      //   await setIsRutOk(false)
      // }
    }
  }

  const ParseFone = async () => {
    let fone = Telefono
      if(fone.length===9){
        // console.log(fone)
        setTelefono(fone.substring(0,1)+" "+fone.substring(1,5)+" "+fone.substring(5,9))
      }
  }

  const SetChangeAddrres = async () => {
    // console.log(Datos, Telefono, Rut)
    setLoaddingData(true)
    return new Promise(async (resolve, reject) => {
      let query = await fetch(URL_CTRL_TRASP+'change_addrress',{
        method: 'POST',
        headers: {
          'authorization': "paico2021",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Datos, Telefono, Rut,TypeOfChange, USER:LoginState.user
        })
      })
      let responsito = await query.json();
      // console.log(responsito)
      if(responsito.data.success){
        alert('Se envió una notificación por correo a GO Transportes para activar el cambio o nuevo ingreso de dirección. ')
        window.location.reload()
        setLoaddingData(false)
      }else{
        resolve(responsito.data)
        setLoaddingData(false)
      }
    })
  }

  //disparadores

  useEffect(() => {
    FindRutData(Rut)
  }, [Rut])

  useEffect(() => {
    setDatos({...Datos,
      "georef": LatLong,
      "direccion":HereAdres
    })
  }, [LatLong,HereAdres])

  useEffect(() => {
    let key_ = Object.keys(Datos)
    setIsValidate(false) 
    for (const ittm of key_) {
      if(Datos[ittm] === "") setIsValidate(true)
    }
  }, [Datos])

  useEffect(() => {
    ParseFone()
  }, [Telefono])

  return (
    <>
    <HeaderMenu Pag_Sel={'cambiar_direccion'} />

    <div className='container-fluid mt-2 mb-5'>
      <div className='row'>
        <div className='col-12 py-2'>
          <h5>Cambiar o Ingresar nueva Dirección</h5>
        </div>
        <div className='col-12 col-md-3'>
          <div className="form-group performance-2">
            <label htmlFor="exampleInputEmail1">* Rut</label>
            <input type="text" name='rut' value={Rut} onChange={(e)=>ParseRut(e)} className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingrese Rut" autoComplete='off' />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className="form-group performance-2">
            <label>* Nombre</label>
            <input type="text" className="form-control form-control-sm" readOnly={IsRutOk} name='nombre' value={Datos.nombre} onChange={(e)=>setDatos({...Datos, [e.target.name]:e.target.value.toUpperCase()})} placeholder="Ingrese Nombre" autoComplete='off' />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>
        <div className='col-12 col-md-3'>
          <div className="form-group performance-2">
            <label> Teléfono</label>
            <input type="tel" name='telefono' value={Telefono} className="form-control form-control-sm" onChange={(e)=>setTelefono(e.target.value)} placeholder="9 1234 5678" autoComplete='off'/>
            {/* <small id="emailHelp"className="form-text text-muted">si no conose el numero completelo con ceros</small> */}
          </div>
        </div>

          <div className='col-12'>
            <h5>Seleccione la dirección</h5>
            <DisplayMap setLatLong={setLatLong} setHereAdres={setHereAdres} />
          </div>

        <div className='col-12 col-md-4'>
          <div className="form-group performance-2">
            <label>* Georeferencia</label>
            <input type="text" value={Datos.georef} className="form-control form-control-sm" id="exampleInputEmail1" readOnly placeholder="Georeferencia" />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>
        <div className='col-12 col-md-8'>
          <div className="form-group performance-2">
            <label>* Dirección</label>
            <input type="text" name='direccion' value={Datos.direccion} onChange={(e)=>setDatos({...Datos,[e.target.name]:e.target.value.toUpperCase()})} className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Direccion" autoComplete='off' readOnly />
            {/* <small id="emailHelp"className="form-text text-muted">Obligatorio</small> */}
          </div>
        </div>

        <div className='col-12 text-right'>
         
              {
                LoaddingData?
                <button
                  disabled={true}
                  className='btn btn-sm btn-info px-5'><ChangeCircleOutlinedIcon /> Cargando Datos. . .
                </button>
                :
                <button
                  disabled={IsValidate}
                  onClick={()=>SetChangeAddrres()}
                  className='btn btn-sm btn-info px-5'><ChangeCircleOutlinedIcon /> Enviar Datos
                </button>
              }
              
            
        </div>
      </div>
    </div>
    </>
  )
}



// const params= new URLSearchParams(window.location.search);
// console.log(params.get('numero'));