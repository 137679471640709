import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import LoginContext from '../context/login_context/LoginContext';

import MenuIcon from '@mui/icons-material/Menu';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import './main.css'
import { MakeAFile } from '../api/MakeAFile.api';

import dayjs from 'dayjs'
import { CallTheRealTime } from '../api/CallTheRealTime.api';
import { VERSION } from '../router/Url';
var weekOfYear = require('dayjs/plugin/weekOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs().tz('America/Santiago')
let formater = new Intl.DateTimeFormat('es-CL', {timeZone: 'America/Santiago'})
let notNow = new Date()
// notNow.setDate(notNow.getDate()+7)
let date_static = notNow.setHours(16,0,0,0);
let date_static_friday = notNow.setHours(12,0,0,0);
let date_static_online = notNow.setHours(7,0,0,0);

let formatFecha = formater.format(date_static)


export const HeaderMenu = ({Pag_Sel}) => {
  const navigate = useNavigate();
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [PageSelcected, setPageSelcected] = useState(Pag_Sel)
  const [IsHiddedn, setIsHiddedn] = useState(true);
  // console.log(LoginState)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleCloseSession = async () => {
    CloseLogin()
    navigate('/')
  }

  const Make_A_File_whit_all_Data = async () => {
    MakeAFile();
  }

  // compara las fechaspara habiliatar o bloquear la plataforma
  const CompareHourToAplicateChanges = async (fecha) => {
    
      let realTime = await CallTheRealTime()
      
      let DayLimit = '';
      let DayAntes = '';
      let a = new Date(fecha);
      let b = new Date(date_static);
      let c = new Date(date_static_friday);
      let wakeup = new Date(date_static_online);
      let hrsServ = realTime.hora
      // console.log('num dia',dayjs().day(0)['$d'])
      // console.log(a.getHours(),b.getHours(),c.getHours())
      if(dayjs().isSame(dayjs().day(0), 'day')){
        setIsHiddedn(false)
      }else{
        if(LoginState.area === "SSGG"){
          // console.log('SSGG')
          DayLimit = dayjs().day(0)
          DayAntes = dayjs().day(6)
  
          let antes = dayjs().isBefore(dayjs(DayLimit)['$d'])
          let isSame = dayjs().isSame(DayAntes, 'day')
          // console.log('LIMITE=>',dayjs(DayLimit)['$d'])
          // console.log('antes=>',antes)
          // console.log('Es IGUAL=>',isSame)
          // console.log('hours',a.getHours(),b.getHours())
  
          if(antes){
            if(isSame){
              if(hrsServ < b.getHours() && hrsServ >= wakeup.getHours()){
                setIsHiddedn(true)
                // console.log('a')
              }else{
                setIsHiddedn(false)
                // console.log('b')
              }
            }else{
              setIsHiddedn(true)
              // console.log('c')
            }
          }else{
            // if(a.getHours() < b.getHours() && a.getHours() >= wakeup.getHours()){
            //   setIsHiddedn(true)
              // console.log('d')
            // }else{
              setIsHiddedn(true)
              // console.log('e')
            // }
          }
        }else{
          DayLimit = dayjs().day(6)
          DayAntes = dayjs().day(5)
  
          let antes = dayjs().isBefore(dayjs(DayLimit)['$d'])
          let isSame = dayjs().isSame(DayAntes, 'day')
          // console.log('LIMITE=>',dayjs(DayLimit)['$d'])
          // console.log('antes=>',antes)
          // console.log('Es IGUAL=>',isSame)
          // console.log('hours',a.getHours(),b.getHours())
  
          if(antes){
            if(isSame){
              if(hrsServ < c.getHours() && hrsServ >= wakeup.getHours()){
                setIsHiddedn(true)
                // console.log('f')
              }else{
                setIsHiddedn(false)
                // console.log('g')
              }
            }else{
              
              if(hrsServ < b.getHours() && hrsServ >= wakeup.getHours()){
                setIsHiddedn(true)
                // console.log('h')
              }else{
                setIsHiddedn(false)
                // console.log('i')
              }
            }
          }else{
            // if(a.getHours() < b.getHours()){
              setIsHiddedn(false)
              // console.log('j')
            // }else{
            //   setIsHiddedn(false)
            //   console.log('f')
            // }
          }
        }
      }
    }

  useEffect(() => {
    CompareHourToAplicateChanges(Date.now())
    // console.log("time........")
    let intervalo = setInterval(() => {
      CompareHourToAplicateChanges(Date.now())
      // console.log("time........")
    }, 10000);
    return () => {
      clearInterval(intervalo)
    } 
  }, [])

  return (
    <>
      <div className='container-fluid header mb-2 py-1'>
        <div className='row'>
          <div className='col-3'>
            <img src='/favicon.png' height={'50px'}/>
          </div>
          <div className='col-9 p-auto m-auto'>
            <div className='row'>
              <div className='col-10'>
                <div className='row'>
                  <div className='col-12 hidden-xs col-md-6'>
                    <b>{LoginState.planta}</b>
                  </div>
                  <div className='col-12 col-md-6 text-right'>
                    {LoginState.user}
                  </div>
                </div>
              </div>
              <div className='col-2 text-right  p-auto m-auto'>
                <span className='menubtn' onClick={toggleDrawer('right', true)}><MenuIcon/></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <div className='menu-container'>
            <img src='/logo512.png' style={{height: "150px", }} className='px-5 ml-4' />
            <hr/>
            <br/>
            {/*   */}

            {/* <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='buscar'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/buscar')}><PlagiarismOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Editar Transporte</p>
            </div> */}

            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='carga_masiva'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/carga_masiva')}><UploadFileOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Cargar Transporte</p>
            </div>
            
            {
              IsHiddedn?
                <div className='' onClick={toggleDrawer('right', false)}>
                  <p className={PageSelcected==='cambiar_direccion'?'menu-item selected-item pr-5':'menu-item pr-5'} onClick={()=>navigate('/cambiar_direccion')}><PublishedWithChangesIcon className='color-icon-menu'/><span className='spacing'></span> Cambiar o Ingresar Dirección</p>
                </div>
              :null
            }

            {
              String(LoginState.user).startsWith('Alexandra Olea')?
              <div className='' onClick={toggleDrawer('right', false)}>
                <p className={PageSelcected==='mantenedor'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/mantenedor')}><ManageAccountsIcon className='color-icon-menu'/><span className='spacing'></span> Mantenedor </p>
              </div>:null
            }
            

            <div className='' onClick={toggleDrawer('right', false)}>
              <p className='menu-item' onClick={()=>Make_A_File_whit_all_Data()}><MoveToInboxIcon className='color-icon-menu'/><span className='spacing'></span> Descargar Archivo</p>
            </div>
            <br/>
            <hr/>
            <p className='menu-item' onClick={()=>HandleCloseSession()}>
              <OutputOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Salir
            </p>
            <div className='menu-footer-version'>
              <small>version {VERSION}</small>
            </div>
        </div>
      </Drawer>

    </>
  )
}
