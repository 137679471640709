import React, { useEffect, useLayoutEffect, useState } from "react";
import L from "leaflet";
import 'leaflet-control-geocoder';
import 'leaflet/dist/leaflet.css';
import './geocode.css';
// import icono from '../assets/marker-icon.png'
import icono from '../assets/bus_ping_1.png'

import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { GeoSearchControl } from 'leaflet-geosearch';
import { GetHere } from "../api/GetHere.api";
const provider = new OpenStreetMapProvider({
  params: {
    'accept-language': 'CL', // render results in Dutch
    countrycodes: 'CL', // limit search results to the Netherlands
    addressdetails: 1, // include additional address detail parts
  },
});

const DisplayMap = ({setLatLong, setHereAdres}) => {
  const [mapState, setmapState] = useState(null)
  const [map_PopUp, setmap_PopUp] = useState(null)
  const [map_Marker_, setmap_Marker_] = useState(null)
  const [LatLng_, setLatLng_] = useState("")

  const apiKey = "YAAPK7a677ea48a1045f4b970f69604e6100d3KJJNRcPINSaFr1hFmQS6h4IWNCI3jFpV6wSaeGD-z05Diz1EDSLUy_he5lmNWKn";

    const mapStyles = {
        width: "100%",
        height: "300px",
        border: "1px solid rgb(216, 216, 216)"
      };
      const layer = L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
        maxZoom: 19
      });
      
  
    const mapParams = {
      center: [-33.69030, -71.04609],
      zoom: 13,
      layers: [layer]
    };

    const LoadMap = async () => {
      const map = L.map("map", mapParams)
      // // geosearching functional
      // L.Control.geocoder({defaultMarkGeocode: false}).on('markgeocode', function(e) {
      //   var bbox = e.geocode.bbox;
      //   var poly = L.polygon([
      //     bbox.getSouthEast(),
      //     bbox.getNorthEast(),
      //     bbox.getNorthWest(),
      //     bbox.getSouthWest()
      //   ]).addTo(map);
      //   map.fitBounds(poly.getBounds());
      // })
      // .addTo(map);

      map.addControl(
        new GeoSearchControl({
          provider,
          style: 'button',
          autoComplete: true,
          showMarker: true,
          animateZoom: true,
          searchLabel: 'Ingrese Direccion',
          autoClose: true,
          showPopup: true,
        }),
      );
     
      const popUp = L.popup()
      setmap_PopUp(popUp)
      setmapState(map)
    }

    const TryObtainEvent = async () => {
      let mark01 = ''
      mapState.addEventListener('click',async(e)=>{
        setLatLng_(e.latlng)

        setLatLong(e.latlng.lat+", "+ e.latlng.lng)       

        // map_PopUp.setLatLng([e.latlng.lat, e.latlng.lng]).setContent("aqui vivo!").openOn(mapState);
        
        if(mark01!=="")mapState.removeLayer(mark01)
        let elIcono = L.icon({
          iconUrl: icono,
          iconSize: [30, 60],
          iconAnchor: [16, 60],
        })
        mark01 = L.marker([e.latlng.lat, e.latlng.lng], {icon: elIcono})
        mapState.addLayer(mark01)
        //to api get HERE MPAS /*-*/*-*/*-*/*-*/*-*/*-*/*-*/
        // let GetAddrres = await GetHere({at:e.latlng.lat.toFixed(5)+","+e.latlng.lng.toFixed(5)})
        // await setHereAdres(GetAddrres)
        // console.log(GetAddrres)
      })
      mapState.on('click', (e)=>{
        // console.log('tap',[e.latlng.lat, e.latlng.lng])
        // setLatLng_(e.latlng)
        // setLatLong(e.latlng.lat+", "+ e.latlng.lng)
        // let keys_ = Object.keys(e.target._layers)
        // console.log(keys_)
        // let newObject = {}
        // newObject = e.target._layers
        
        // // map_PopUp.setLatLng([e.latlng.lat, e.latlng.lng]).setContent("aqui vivo!").openOn(mapState);
        // L.marker([e.latlng.lat, e.latlng.lng], {draggable: true}).addTo(mapState)
        
      })
    }

    const GetGeocedeReverse = async (LatLng_) => {
      // console.log(LatLng_)
       // to api get HERE MPAS /*-*/*-*/*-*/*-*/*-*/*-*/*-*/
       let GetAddrres = await GetHere(LatLng_)
       await setHereAdres(GetAddrres)
      //  console.log(GetAddrres)
    }

    useLayoutEffect(() => {
      LoadMap()
    }, []);
    
    useEffect(() => {
      // console.log(LatLng_)
      if(LatLng_ !== ""){
        let elIcono = L.icon({
          iconUrl: icono,
          iconSize: [25, 45],
          iconAnchor: [16, 45],
        })
        // new L.circle([LatLng_.lat,LatLng_.lng], {
        //   color: 'red',
        //   fillColor: '#f03',
        //   fillOpacity: 0.5,
        //   radius: 10
        // }).addTo(mapState)
        // L.marker([LatLng_.lat,LatLng_.lng],{icon: elIcono, interactive: true}).addTo(mapState)

        GetGeocedeReverse(LatLng_)
      }
    }, [LatLng_])
    
    return (
      <div className="mb-3">
        <div id="map" style={mapStyles} onClick={()=>TryObtainEvent()} />
          
        {/* <img src={icono} /> */}
      </div>
    )
  };
  
  export default DisplayMap;
  